<template>
  <Card
    :handleControl="welcomeControl"
    class="h-100 align-center kiwi-mockup-test mt-4 mt-md-4"
    v-for="(freeMockTest, key) in freeMockTests"
    :key="key"
  >
    <v-row class="align-center">
      <v-col cols="12" sm="8" md="9" lg="10">
        <div class="d-flex flex-wrap align-center">
          <img
            src="/assets/mocktest-icon.svg"
            width="36"
            height="36"
            class="mr-2"
          />
          <span class="font-weight-bold kiwi-fs-20 kiwi-lh-1-sm" style="flex: 1"
            >Available Mock Tests</span
          >
        </div>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="4" md="3" lg="2" class="text-sm-right">
        <router-link
          :to="{ name: 'mock-tests' }"
          class="kiwi-text-black text-decoration-none font-weight-semi-bold kiwi-fs-16"
          >View All <v-icon class="mdi mdi-arrow-right" size="20px"></v-icon
        ></router-link>
      </v-col>
    </v-row>

    <v-row
      no-gutters
      class="mt-4 mt-md-6 border kiwi-border-light-grey-4 kiwi-radius-8 overflow-hidden"
    >
      <v-col cols="12" md="12" lg="3">
        <v-sheet
          class="kiwi-bg-gradient-2 position-relative overflow-hidden kiwi-text-white kiwi-border-r-1 kiwi-border-r-light-grey-4 h-100"
          min-height="184"
          style="border-radius: 8px 0 0 8px"
        >
          <img
            src="/assets/eclipse-mask.png"
            class="position-absolute z-1 top-0"
            alt=""
            style="object-fit: contain; right: -150px"
          />
          <div class="content position-relative z-2 pa-4 pa-md-6">
            <div class="title kiwi-fs-24 font-weight-bold">
              {{ freeMockTest.description }}
            </div>
            <div class="d-flex duration">
              <img src="/public/assets/clock-white.svg" class="mr-2" alt="" />
              <span>Duration: {{authStore.isSAT ? convertMinutesToHoursAndMinutes(freeMockTest.total_time_duration) : freeMockTest.total_time_duration/60 + 'hrs' ?? 0 }} </span>
            </div>
          </div>
        </v-sheet>
      </v-col>
      <v-col sm="12" md="12" lg="6">
        <v-sheet
          class="kiwi-bg-white position-relative overflow-hidden kiwi-text-black kiwi-border-r-1 kiwi-border-r-light-grey-4 h-100"
          min-height="184"
        >
          <div class="content position-relative z-2 pa-4 py-md-6">
            <v-row class="w-100" no-gutters>
              <v-col cols="12" md="12" lg="7" class="mb-3 mb-lg-0">
                <div class="d-flex flex-wrap align-start">
                  <img
                    src="/public/assets/module-icon.svg"
                    class="mr-3"
                    alt=""
                  />
                  <div style="flex: 1">
                    <span class="d-block font-weight-semi-bold kiwi-fs-16"
                      >Modules and Questions</span
                    >
                    <div
                      class="author-wrapper d-flex flex-wrap has-dot-seprator kiwi-fs-14"
                    >
                      <template v-if="authStore.isSAT">
                        <span>{{'2 Sections '}}</span>
                        <v-icon class="mdi mdi-circle-small px-4"></v-icon>
                      </template>
                      <span v-html="'4 Modules'" />
                      <v-icon class="mdi mdi-circle-small px-4"></v-icon>
                      <span
                        >{{
                          freeMockTest.total_number_of_question
                        }}
                        Questions</span
                      >
                    </div>
                  </div>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" md="12" lg="5">
                <div class="d-flex flex-wrap align-start">
                  <img
                    src="/public/assets/score-icon.svg"
                    class="mr-3"
                    alt=""
                  />
                  <div>
                    <span class="d-block font-weight-semi-bold kiwi-fs-16"
                      >Overall Score</span
                    >
                    <span
                      v-if="authStore.isSAT"
                      class="kiwi-text-orange font-weight-semi-bold kiwi-fs-16"
                    >{{
                        parseFloat(freeMockTest.exam_attempt_data?.score ?? '--')
                      }}/{{
                        freeMockTest.total_marks ?? 90
                      }}</span
                    >
                    <span
                      class="kiwi-text-orange font-weight-semi-bold kiwi-fs-16"
                      v-else>{{
                        freeMockTest.exam_attempt_data?.marks?.Overall ?? '--'
                      }}/{{
                        freeMockTest.total_marks ?? 90
                      }}</span
                    >
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-divider class="my-4"></v-divider>
            <v-btn
              class="kiwi-border-primary kiwi-bg-white kiwi-text-primary kiwi-fs-16 text-capitalize"
              size="small"
              block-sm
              rounded="xl"
              variant="outlined"
              style="padding: 0px 10px; font-size: 14px"
              @click="resume(freeMockTest)"
              v-if="freeMockTest.is_attempted && !freeMockTest.is_completed"
            >
              Resume
            </v-btn>
            <v-btn
              v-else-if="freeMockTest.is_completed"
              class="kiwi-bg-white border kiwi-bg-white kiwi-border-light-grey kiwi-fs-16 text-capitalize"
              size="small"
              block-sm
              rounded="xl"
              variant="flat"
              style="padding: 0px 10px; font-size: 14px"
              @click="mockTestAction(freeMockTest)"
            >
              View Result
            </v-btn>
            <v-btn
              v-else
              class="kiwi-button text-none kiwi-bg-primary kiwi-text-white kiwi-fs-16 font-weight-semi-bold"
              variant="flat"
              rounded="xl"
              size="large"
              @click="mockTestAction(freeMockTest)"
            >
              Start Test
            </v-btn>
          </div>
        </v-sheet>
      </v-col>
      <v-col sm="12" md="12" lg="3">
        <v-sheet
          class="kiwi-bg-light-grey-5 position-relative overflow-hidden kiwi-text-black h-100 d-flex flex-wrap align-center justify-center"
          min-height="184"
        >
          <v-progress-circular
            :model-value="freeMockTest.attempted_question_percentage"
            :size="146"
            :width="10"
          >
            <template v-slot:default>
              <div
                class="inner-status d-flex flex-column flex-wrap align-center justify-center"
              >
                <span
                  class="kiwi-fs-12 font-weight-medium"
                  style="color: #5f6563"
                  >Status</span
                >
                <span class="kiwi-fs-24 font-weight-bold"
                  >{{ freeMockTest.attempted_question_percentage }}%</span
                >
                <span
                  class="kiwi-fs-12 font-weight-medium"
                  :class="
                    !freeMockTest.is_attempted
                      ? 'kiwi-text-orange'
                      : 'kiwi-text-success'
                  "
                  >{{
                    !freeMockTest.is_attempted
                      ? notStartdStatus
                      : freeMockTest.is_completed
                        ? completeStatus
                        : inProgressStatus
                  }}</span>
              </div>
            </template>
          </v-progress-circular>
        </v-sheet>
      </v-col>
    </v-row>
  </Card>
  <PromptDialog v-if="prompt" v-bind="prompt" />
</template>

<script setup>
import Card from "@/components/Dashboard/ContentSheet.vue";
import { ref, computed } from "vue";
import { useDashboardStore } from "@/stores/dashboard-store";
import { storeToRefs } from "pinia";
import { useTestAction } from "@/composables/useTestAction";
import {convertMinutesToHoursAndMinutes} from "@/utils/utils";
import {useAuthStore} from "@/stores/auth/authStore";

const authStore = useAuthStore();
const dashboardStore = useDashboardStore();
const { dashboardData } = storeToRefs(dashboardStore);
const { mockTestAction, start } = useTestAction("mock-test");
const prompt = ref(null);
const freeMockTests = computed(() => {
  return dashboardData.value && dashboardData.value.free_mock_tests
    ? dashboardData.value.free_mock_tests
    : [];
});
const welcomeControl = ref({
  bg: "white",
  textColor: "black",
});

const progress = ref(Number);
progress.value = 0;

const notStartdStatus = ref("Not Started");
const inProgressStatus = ref("In Progress");
const completeStatus = ref("Completed")
const resume = (test) => {
  prompt.value = {
    title: "Confirm?",
    content:
      "You did not finish this test last time. Do you want to continue from your saved session?",
    actions: [
      {
        text: "Close",
        onClick: () => (prompt.value = null),
      },
      {
        text: "Continue",
        type: "primary",
        onClick: () => {
          prompt.value = null;
          mockTestAction(test);
        },
      },
    ],
  };
};
</script>

<style>
.kiwi-mockup-test .v-progress-circular__underlay {
  stroke: white !important;
}
.v-progress-circular__overlay {
  stroke: #1ca972 !important;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1);
}
</style>
